var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', [_c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" 说明：全新重构设计微信小程序，现已开源！后端正在XBoot中开发 "), _c('a', {
    attrs: {
      "href": "http://xpay.exrick.cn/pay?xboot",
      "target": "_blank"
    }
  }, [_vm._v("立即获取完整版")])]), _c('div', {
    staticClass: "weapp"
  }, [_c('Poptip', {
    attrs: {
      "trigger": "hover",
      "title": "打开微信扫一扫"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2019/10/06/yrtuPTL9DXQUvd2.jpg",
      "width": "180px"
    }
  })]), _c('Button', {
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      "type": "primary",
      "icon": "md-qr-scanner",
      "shape": "circle"
    }
  }, [_vm._v("微信扫码体验")])], 1), _c('Button', {
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      "to": "https://www.bilibili.com/video/av70226175",
      "target": "_blank",
      "shape": "circle",
      "icon": "ios-videocam"
    }
  }, [_vm._v("小程序宣传视频")]), _c('Button', {
    attrs: {
      "to": "https://github.com/Exrick/xmall-weapp",
      "target": "_blank",
      "shape": "circle",
      "icon": "logo-github"
    }
  }, [_vm._v("Github地址")])], 1), _c('div', {
    staticClass: "weapp-imgs"
  }, [_c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2019/07/08/5d22e3df51b3b41948.png"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2019/07/08/5d22e3def39a024428.png"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2019/07/08/5d22e3df6ee8d14238.png"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2019/07/08/5d22e3df80a8539956.png"
    }
  })])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };